import { Component, HostListener, OnInit } from '@angular/core';
import { UpdateService } from './service/data.service';
import { ChatService } from './components/chat/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  pathName: String = window.location.pathname;

  title = 'kctickets';
  cartCountSubscribe: any;
  isShowFooter: boolean = false;
  footerSubscribe: any;

  // @HostListener('window:beforeunload', ['$event'])
  // unloadNotification($event: any) {
  //   console.log('clear affilate key');
  //   localStorage.removeItem('fromAffilateKey')
  // }
  // ngOnDestroy() {

  //   alert('hjfjkdhsf')
  // }

  constructor(private dataservice: UpdateService, public chatService: ChatService,) { }

  ngOnInit() {
    this.dataservice.changeCartCount(true);
    this.dataservice.changeUnreadMessageCount(true)

    this.cartCountSubscribe = this.dataservice.affilateCheckObservable.subscribe((res) => {
      if (res)
        this.checkAffilateKey();
    });
    this.footerSubscribe = this.dataservice.footerCheckObservable.subscribe((res) => {
      if (res)
        this.isShowFooter = true;
    });

    this.chatService.getMessages().subscribe((res) => {
      this.dataservice.changeUnreadMessageCount(true)
    });

  }
  ngOnDestroy() {
    this.cartCountSubscribe.unsubscribe();
    this.footerSubscribe.unsubscribe();
  }
  checkAffilateKey() {

    var currentDate = new Date().toString();
    var affilateSessionObject = JSON.parse(sessionStorage.getItem('affilateSessionObject'));
    if (affilateSessionObject) {
      var expirationDate = affilateSessionObject.expiresAt;
      if (Date.parse(currentDate) > Date.parse(expirationDate)) {
        sessionStorage.removeItem('affilateSessionObject');
      }
    }
  }
}
