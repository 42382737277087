import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeInt'
})
export class ChangeIntPipe implements PipeTransform {

  transform(value: any, args: any[]): any {
    if( args === null)
      return value;
    
    if(value !== null)
    {
        return parseInt(value)
    }
    
  }

}
