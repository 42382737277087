import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { ToastrService } from "ngx-toastr";

import { Router } from "@angular/router";
import { BASE_URL, IMAGE_URL, FAKE_API } from "../constant";
import { catchError, map, tap, timeout } from "rxjs/operators";
import { Observable, of, throwError, Subject, BehaviorSubject } from "rxjs";
import { UpdateService } from "./data.service";
import { Meta } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  eventPrivate: boolean = false;
  // baseURL = 'http://192.168.0.156:10000/api/admin/';
  public serverURL = BASE_URL + "/api/";
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private dataService: UpdateService,
    private router: Router,
    private metaService: Meta
  ) {}

  getHeader() {
    var session = JSON.parse(localStorage.getItem("session"));
    const headers = new HttpHeaders()
      .set("Authorization", session ? session.user_token.access_token : "")
      .set("Content-Type", "application/json");
    console.log(headers);

    return headers;
  }

  checkLogin() {
    if (localStorage.getItem("session")) {
      return true;
    } else {
      return false;
    }
  }
  checkProfileCompleted() {
    let session = JSON.parse(localStorage.getItem("session"));
    let is_profile_completed = session.user.is_profile_completed;
    if (is_profile_completed) {
      return true;
    } else {
      return false;
    }
  }
  checkStripeConnected() {
    let session = JSON.parse(localStorage.getItem("session"));
    let is_stripe_connected = session.user.is_stripe_connected;
    if (is_stripe_connected) {
      return true;
    } else {
      return false;
    }
  }

  getUserData() {
    let session = JSON.parse(localStorage.getItem("session"));
    if (session) {
      var request = { user_id: session.user.id };
      this.httpRequest("get", "user", request).subscribe(
        (data: any) => {
          console.log(data);
          if (data)
            localStorage.setItem(
              "session",
              JSON.stringify({
                user: data,
                user_token: JSON.parse(localStorage.getItem("session"))
                  .user_token,
              })
            );
        },
        (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.dataService.changeLoginPopup(true);
          }
        }
      );
    }
  }

  getUrl(url) {
    return this.serverURL + url;
  }

  getCompanyAndBrand(): Observable<any> {
    return this.http.get<any>("assets/data/get/event-detail.json");
  }

  httpRequest(type, url, data): Observable<any> {
    if (type == "get") {
      return this.http.get(this.getUrl(url), {
        params: data,
        headers: this.getHeader(),
      });
    } else if (type == "getwithoutheader") {
      return this.http.get(this.getUrl(url), {
        params: data,
        // headers:this.getHeader()
      });
    } else if (type == "post") {
      return this.http
        .post(this.getUrl(url), data, { headers: this.getHeader() })
        .pipe(
          // if responce takes more than one minute
          timeout(60000),
          // ...errors if any
          catchError(this.handleError),
          // ...and calling .json() on the response to return data
          map((res: HttpResponse<any>) => res)
        );
    } else if (type == "put") {
      return this.http
        .put(this.getUrl(url), data, { headers: this.getHeader() })
        .pipe(
          // if responce takes more than one minute
          timeout(60000),
          // ...errors if any
          catchError(this.handleError),
          // ...and calling .json() on the response to return data
          map((res: HttpResponse<any>) => res)
        );
    } else if (type == "delete") {
      let data1 = {
        body: data,
        headers: this.getHeader(),
      };
      return this.http.delete(this.getUrl(url), data1).pipe(
        // if responce takes more than one minute
        timeout(60000),
        // ...errors if any
        catchError(this.handleError),
        // ...and calling .json() on the response to return data
        map((res: HttpResponse<any>) => res)
      );
    }
  }

  showSuccess(msg, title) {
    console.log("Show Success. ");

    this.toastr.success(msg, title, {
      timeOut: 1500,
      positionClass: "toast-top-right",
      progressBar: true,
    });
  }

  showError(msg, title) {
    this.toastr.error(msg, title, {
      timeOut: 2000,
      positionClass: "toast-top-right",
      progressBar: true,
    });
  }
  showWarning(msg, title) {
    this.toastr.warning(msg, title, {
      timeOut: 2000,
      positionClass: "toast-top-right",
      progressBar: true,
    });
  }

  showWentWrong() {
    this.toastr.error("Something Went Wrong", "Error", {
      timeOut: 1500,
      positionClass: "toast-top-right",
      progressBar: true,
    });
  }
  unauthorized() {
    this.toastr.error("Please login to Continue.", "Unauthorized", {
      timeOut: 1500,
      positionClass: "toast-top-right",
      progressBar: true,
    });
    localStorage.clear();
    this.router.navigate(["/login"]);

    this.dataService.changeCartCount(true);
    this.dataService.changeLoginPopup(true);
  }

  logout() {
    this.router.navigate(["/"]);
    localStorage.removeItem("session");
    this.dataService.changeCartCount(true);
    this.showSuccess("User Logged out successfully", "Logged Out");
  }
  Forbidden_logout() {
    this.router.navigate(["/"]);
    localStorage.removeItem("session");
    this.dataService.changeCartCount(true);
  }

  navigate(url) {
    this.router.navigateByUrl(url);
  }

  removeFine(id) {
    this.httpRequest("post", "remove-fine-type", { id: id }).subscribe(
      (res) => {
        this.showSuccess("Fine Has Been Deleted", "Fine Deleted");
      },
      (err) => {
        this.showWentWrong();
      }
    );
  }

  sortObject = function (objArray, name) {
    if (objArray)
      return objArray.sort(function (a, b) {
        var textA = a[name].toUpperCase();
        var textB = b[name].toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    else return [];
  };

  setMetaData(title?, desc?, image?) {
    if (title) {
      this.metaService.updateTag({
        name: "title",
        content: title,
      });
      this.metaService.updateTag({
        property: "og:title",
        content: title,
      });
      this.metaService.updateTag({
        property: "twitter:title",
        content: title,
      });
    }
    if (desc) {
      this.metaService.updateTag({
        name: "description",
        content: desc,
      });
      this.metaService.updateTag({
        property: "og:description",
        content: desc,
      });
      this.metaService.updateTag({
        property: "twitter:description",
        content: desc,
      });
    }

    if (image) {
      this.metaService.updateTag({
        name: "image",
        content: image,
      });
      this.metaService.updateTag({
        property: "og:image",
        content: image,
      });
    }
  }

  uploadMedia(data) {
    // let request: any = data;
    let request = new FormData();
    request.append("file", data);

    // return this.http.get<any>(this.serverURL + '/homeFeed',{headers:headers});
    return this.http
      .post(IMAGE_URL, request, {
        headers: this.getHeader(),
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        // if responce takes more than one minute
        timeout(60000),
        // ...errors if any
        catchError(this.handleError),
        // ...and calling .json() on the response to return data
        map((res: any) => {
          return res;
        })
      );
  }

  handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error("Client Side Error :", errorResponse.error.message);
    } else {
      console.error("Server Side Error :", errorResponse);
    }
    // return an observable with a meaningful error message to the end user
    return throwError(
      errorResponse
      // "There is a problem with the service. We are notified & working on it. Please try again later."
    );
  }
}
