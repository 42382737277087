import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';


// import { HomeComponent } from './components/home/home.component';
// import { BrowseEventsComponent } from './components/browse-events/browse-events.component';
// import { EventDetailComponent } from './components/event-detail/event-detail.component';
// import { CartComponent } from './components/cart/cart.component';
// import { ProfileComponent } from './components/profile/profile.component';

// import { CheckOutComponent } from './components/check-out/check-out.component';
// // import {CartComponent} from './components/cart/cart.component';
// import { ReceiptComponent } from './components/receipt/receipt.component';
// import { AppComponent } from './app.component';
// import { OrganizersEventComponent } from './components/organizers-event/organizers-event.component';
// import { AddEventComponent } from './components/add-event/add-event.component';
// import { MyeventsComponent } from './components/myevents/myevents.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
// import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
// import { PackagesComponent } from './packages/packages.component';
// import { CreateRentalsComponent } from './rentals/create-rentals/create-rentals.component';
// import { ChatComponent } from './components/chat/chat.component';

// auth guard 
import { AuthGuard } from './auth.guard';


const routes: Routes =
  [
    {
      path: '',
      // component:AppComponent,
      children:
        [
          {
            path: '',
            loadChildren: '../app/components/home/home.module#HomeModule'
          },
          {
            path: 'home',
            loadChildren: '../app/components/home/home.module#HomeModule'
          },
          {
            path: 'browse-events',
            loadChildren: '../app/components/browse-events/browse-events.module#BrowseEventsModule',
          },
          {
            path: 'browse-rentals',
            loadChildren: '../app/components/browse-rental/browse-rentals.module#BrowseRentalModule',
          },

          {
            path: 'cart',
            loadChildren: '../app/components/cart/cart.module#CartModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'chat',
            loadChildren: '../app/components/chat/chat.module#ChatModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'check-out',
            loadChildren: '../app/components/check-out/check-out.module#CheckOutModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'profile',
            loadChildren: '../app/components/profile/profile.module#ProfileModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'receipt/:uniqueid/:id',
            loadChildren: '../app/components/receipt/receipt.module#ReceiptModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'events/:name/:id',
            loadChildren: '../app/components/organizers-event/organizers-event.module#OrganizersEventModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'rentals/:name/:id',
            loadChildren: '../app/components/organizers-rental/organizer-rentals.module#OrganizerRentalsModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'event/add',
            loadChildren: '../app/components/add-event/add-event.module#AddEventModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'rental/add',
            loadChildren: '../app/rentals/create-rentals/create-rentals.module#CreateRentalsModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'myevents',
            loadChildren: '../app/components/myevents/myevents.module#MyeventsModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'myrentals',
            loadChildren: '../app/components/myrentals/myrentals.module#MyRentalsModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'mywishlist/:name',
            loadChildren: '../app/components/mywishlist/mywishlist.module#MywishlistModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'event-detail/:id',
            loadChildren: '../app/components/event-detail/event-detail.module#EventDetailModule',
          },

          {
            path: 'event/:name/:eventname/:id',
            loadChildren: '../app/components/event-detail/event-detail.module#EventDetailModule',
          },
          {
            path: 'event-organiser',
            loadChildren: '../app/components/event-organiser/event-organiser.module#EventOrganiserModule',
          },
          {
            path: 'rental-detail/:id',
            loadChildren: '../app/rentals/book-rentals/book-rentals.module#BookRentalsModule',
          },
          {
            path: 'rental/:name/:rentalname/:id',
            loadChildren: '../app/rentals/book-rentals/book-rentals.module#BookRentalsModule',
          },
          {
            path: 'attraction-detail/:id',
            loadChildren: '../app/components/event-detail/event-detail.module#EventDetailModule',
          },

          {
            path: 'packages',
            loadChildren: '../app/packages/packages.module#PackagesModule',
          },

          {
            path: 'package-detail/:id',
            loadChildren: '../app/components/package-detail/package-detail.module#PackageDetailModule',
          },
          {
            path: 'event-dashboard/:id',
            loadChildren: '../app/dashboard/dashboard.module#DashboardModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'rental-dashboard/:id',
            loadChildren: '../app/rental-dashboard/rental-dashboard.module#RentalDashboardModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'reset-password/:token',
            loadChildren: '../app/components/reset-password/reset-password.module#ResetPasswordModule',

          },

          {
            path: 'career',
            loadChildren: '../app/components/Static-pages/static-page/static-page.module#StaticPageModule',
          },
          {
            path: 'cancellation-refund-policy',
            loadChildren: '../app/components/Static-pages/static-page/static-page.module#StaticPageModule',
          },
          {
            path: 'privacy-policy',
            loadChildren: '../app/components/Static-pages/static-page/static-page.module#StaticPageModule',
          },
          {
            path: 'terms-of-service',
            loadChildren: '../app/components/Static-pages/static-page/static-page.module#StaticPageModule',
          },
          {
            path: 'about-us',
            loadChildren: '../app/components/Static-pages/static-page/static-page.module#StaticPageModule',
          },
          {
            path: 'faq',
            loadChildren: '../app/components/Static-pages/static-page/static-page.module#StaticPageModule',
          },
          {
            path: 'contact-us',
            loadChildren: '../app/components/Static-pages/static-page/static-page.module#StaticPageModule',
          },
          {
            path: 'verify-email/:token',
            loadChildren: '../app/components/home/home.module#HomeModule',
          },
          {
            path: 'how-it-works',
            loadChildren: '../app/components/works/works.module#WorksModule',
          },
          { path: '**', redirectTo: '' }
        ]
    },

    // {path:'',component:HomeComponent},
    // {path:'profile',component:ProfileComponent},
    // {path:'browse-events',component:BrowseEventsComponent},
    // {path:'event-detail',component:EventDetailComponent},
    // {path:'check-out',component:CheckOutComponent},
    // {path:'cart',component:CartComponent},
    // {path:'receipt',component:ReceiptComponent}

  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
