import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDecimal'
})
export class TwoDecimalPipe implements PipeTransform {

  transform(value: any): any {
    
    if( value === null || value < 0)
      return;
    else{
        
        return parseFloat(value).toFixed(2);
    }
      
  }
}

@Pipe({
  name: 'oneDecimal'
})
export class OneDecimalPipe implements PipeTransform {

  transform(value: any): any {
    
    if( value === null || value < 0)
      return;
    else{
        
        return parseFloat(value).toFixed(1);
    }
      
  }

}
