import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  updateDetails = new Subject<boolean>();

  public LoginPopupSubject = new BehaviorSubject<boolean>(false);
  LoginPopupSubjectObservable = this.LoginPopupSubject.asObservable();

  public copyEventSubject = new BehaviorSubject<boolean>(false);
  copyEventSubjectObservable = this.copyEventSubject.asObservable();
  
  public copyRentalSubject = new BehaviorSubject<boolean>(false);
  copyRentalSubjectObservable = this.copyRentalSubject.asObservable();

  public UnreadMessageSubject = new BehaviorSubject<boolean>(false);
  UnreadMessageObservable = this.UnreadMessageSubject.asObservable();

  public CompleteProfileSubject = new BehaviorSubject<boolean>(false);
  CompleteProfileObservable = this.CompleteProfileSubject.asObservable();

  public receiptHeaderSubject = new BehaviorSubject<boolean>(false);
  receiptHeaderObservable = this.receiptHeaderSubject.asObservable();

  public cartCountSubject = new BehaviorSubject<boolean>(false);
  cartCountSubjectObservable = this.cartCountSubject.asObservable();

  public profileCountSubject = new BehaviorSubject<boolean>(false);
  profileCountSubjectObservable = this.profileCountSubject.asObservable();

  public eventIdSubject = new BehaviorSubject<boolean>(false);
  eventIdSubjectObservable = this.eventIdSubject.asObservable();

  public preview = new BehaviorSubject<boolean>(false);
  previewObservable = this.preview.asObservable();

  public profileImage = new BehaviorSubject<boolean>(false);
  profileImageObservable = this.profileImage.asObservable();

  public profileInfo = new BehaviorSubject<boolean>(false);
  profileInfoObservable = this.profileInfo.asObservable();

  public saveAsDraft = new BehaviorSubject<boolean>(false);
  saveAsDraftObservable = this.saveAsDraft.asObservable();

  public publishEvent = new BehaviorSubject<boolean>(false);
  publishEventObservable = this.publishEvent.asObservable();

  public affilateCheck = new BehaviorSubject<boolean>(false);
  affilateCheckObservable = this.affilateCheck.asObservable();

  public footerCheck = new BehaviorSubject<boolean>(false);
  footerCheckObservable = this.footerCheck.asObservable();

  public cartStatus: BehaviorSubject<String> = new BehaviorSubject<String>('');
  cartStatusObservable = this.cartStatus.asObservable();

  constructor(){}

  changeCartStatus(value): void {
    this.cartStatus.next(value)
  }  
  changeReceiptHeader(value): void {
    this.receiptHeaderSubject.next(value);
  }
  changefooter(value): void {
    this.footerCheck.next(value);
  }
  changeCopyEventSubject(value): void {
    this.copyEventSubject.next(value);
  }
  changeCopyRentalSubject(value): void {
    this.copyRentalSubject.next(value);
  }
  changeLoginPopup(value): void {
    this.LoginPopupSubject.next(value);
  }
  changeUnreadMessageCount(value): void {
    this.UnreadMessageSubject.next(value);
  }
  changeCompleteProfilePopup(value): void {
    this.CompleteProfileSubject.next(value);
  } 
  
  changeCartCount(value): void {
    this.cartCountSubject.next(value);
  }
  changeProfileCount(value): void {
    this.profileCountSubject.next(value);
  }
  changeEventId(value): void {
    this.eventIdSubject.next(value);
  }
  changePreviewValue(value): void {
    this.preview.next(value);
  }
  changeProfileImage(value): void {
    this.profileImage.next(value);
  }
  changeProfileInfo(value): void {
    this.profileInfo.next(value);
  }
  changeDraft(value): void {
    this.saveAsDraft.next(value);
  }
  changePublish(value): void {
    this.publishEvent.next(value);
  }
  changeAffilateCheck(value): void {
    this.affilateCheck.next(value);
  }
}