import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { promise } from 'protractor';
import { ApiService } from './service/api.service';
import Swal from 'sweetalert2';
import { CommonService } from './service/common.service';
import { UpdateService } from './service/data.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  cartCountSubscribe: any;
  constructor(private ApiService : ApiService, private dataservice: UpdateService, private router:Router, private cs : CommonService) {
   
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (this.ApiService.authService()) {
        window.scroll(0,0);
        this.dataservice.changeAffilateCheck(true);
        return true;
      } else {
        this.router.navigate(['']);
        this.cs.unauthorized();
        return false;
      }
    }

  
}
