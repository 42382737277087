import { Injectable } from '@angular/core';





import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpRequest,
  HttpParams,
  HttpEvent,
  HttpEventType
} from "@angular/common/http";
import { Observable, of, throwError, Subject, BehaviorSubject } from "rxjs";
import { catchError, map, tap, timeout } from "rxjs/operators";
import {BASE_URL, IMAGE_URL} from '../constant';

import { Router } from "@angular/router";
// import Swal from "sweetalert2";




@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public actionURL: string;

    // // baseUrl = BASE_URL; // local
    baseUrl = BASE_URL; // dev
    // baseUrl = BASE_URL; // staging
    // baseUrl = BASE_URL; // live
    // baseUrl = "http://104.197.18.112:8000"
  
    public serverURL = this.baseUrl + "/api/";
   
    constructor(private http: HttpClient, private router: Router) {
     
    }
    
  authService(): boolean {
    if (localStorage.getItem("session")) {
      return true;
    } else {
      return false;
    }
  }



    /*
     * extractData function returns outer response
     */
    private extractData(res: any) {
      return res;
    }
  
    /*
     * handleError function for HTTP error handling
     */
    private handleError(errorResponse: HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error("Client Side Error :", errorResponse.error.message);
      } else {
        console.error("Server Side Error :", errorResponse);
  
            // Swal.fire(errorResponse.error.error_description);
  
  
      }
      // return an observable with a meaningful error message to the end user
      return throwError(
        errorResponse
        // "There is a problem with the service. We are notified & working on it. Please try again later."
      );
    }
  
    /*
     * processData function processes inner response
     */
    // tslint:disable-next-line: variable-name





    // public processData(res, swal_err = 1) {
    //   console.log(res);
    //   if (res.msglan !== this.language) {
    //     // something to do there
    //   }
  
    //   if (new Date().getTime() - new Date(res.senttime).getTime() > 60000) {
    //     // check timestamp
    //     // Swal("Request was expired", "", "error");
    //   }
  
    //   if (res.protocolVersion !== this.protocolVersion) {
    //     // check protocol version
    //     // Swal("Protocol Version is invalid", "", "error");
    //   }
  
    //   if (res.code === 0) {
    //     // success response
    //     if (res.content) {
    //       console.log(JSON.parse(atob(res.content)));
    //       return JSON.parse(atob(res.content));
    //     } else {
    //       console.log(res);
    //       return res;
    //     }
    //   } else if (res.code === 301) {
    //     // logout
    //     localStorage.clear();
    //     this.router.navigate(["/login"]);
    //     // Swal(res.message, "", "error");
    //   } else {
    //     if (swal_err) {
    //       // console.log(resHttpParams.message);
    //       // Swal(res.mesHttpParamssage, "", "error");
    //     } else {
    //       HttpParams;
    //       return res.messHttpParamsage;
    //     }
    //   }
    // }
// commented by me





  
    /*
     * call function for common HTTP calls
     */
    call(apiInfo, content = null): Observable<any> {
  
      this.actionURL = this.serverURL + apiInfo.url;
      let headers = new HttpHeaders();
      const session = JSON.parse(localStorage.getItem("session"));
      if (session) {
        headers = new HttpHeaders()
          .set("Authorization", session.user_token.access_token)
          .set("Content-Type", "application/json");
      }
     
      if (apiInfo.header) {
      }
  
      let request: any;
  
      if (content && !apiInfo.upload) {
        request = {};
        request = content;
      }
      /*
       * upload files via http
       */ HttpParams;
      if (apiInfo.upload) {
        request = new FormData();
        request.append("file", content);
        headers = new HttpHeaders().set("Authorization", session.session_id);
      }
  
      switch (apiInfo.method) {
        case "get":
     
          return this.http.get(this.actionURL, { headers, params: request }).pipe(
            // if responce takes more than one minute
            timeout(60000),
            // ...errors if any
            catchError(this.handleError),
            // ...and calling .json() on the response to return data
            map(this.extractData)
          );
  
          break;
  
        case "post":
          // console.log(JSON.stringify(request));
          console.log(headers);
          return this.http.post<any>(this.actionURL, request, { headers }).pipe(
            // if responce takes more than one minute
            timeout(60000),
            // ...errors if any
            catchError(this.handleError),
            // ...and calling .json() on the response to return data
            map(this.extractData)
          );
          break;
  
        case "put":
          return this.http.put<any>(this.actionURL, request, { headers }).pipe(
            // if responce takes more than one minute
            timeout(60000),
            // ...errors if any
            catchError(this.handleError),
            // ...and calling .json() on the response to return data
            map(this.extractData)
          );
  
        case "delete":
          console.log(request);
          return this.http
            .delete<any>(this.actionURL, { headers, params: request })
            .pipe(
              // if responce takes more than one minute
              timeout(60000),
              // ...errors if any
              catchError(this.handleError),
              // ...and calling .json() on the response to return data
              map(this.extractData)
            );
      }
    }

    uploadMedia(data)  {

      // let request: any = data;
      let request = new FormData();
      request.append("file", data);
      var session = JSON.parse(localStorage.getItem('admin_session'));
    
      const headers = new HttpHeaders().set('Authorization', session ? session : '');
    
      // return this.http.get<any>(this.serverURL + '/homeFeed',{headers:headers});
      return this.http.post(IMAGE_URL, request, {
        headers,reportProgress: true,
        observe: "events" }).pipe(
        // if responce takes more than one minute
        timeout(60000),
        // ...errors if any
        catchError(this.handleError),
        // ...and calling .json() on the response to return data
        map((res:any) => {
          return res;
        })
      );
    }


    // upload(formData) {
    //   let request = new FormData();
    //   request.append("file", formData);
    //   const session = JSON.parse(localStorage.getItem("session"));
    //   let headers = new HttpHeaders().set("Authorization", session.session_id);
    //   return this.http
    //     .post<any>(`${this.serverURL}` + "/upload", request, {
    //       headers,
    //       reportProgress: true,
    //       observe: "events"
    //     })
    //     .pipe(
    //       map(event => this.getEventMessage(event, formData)),
    //     );
    // }
    // private getEventMessage(event: HttpEvent<any>, formData) {
    //   switch (event.type) {
    //     case HttpEventType.UploadProgress:
    //       console.log(event.type);
    //       return this.fileUploadProgress(event);
  
    //     case HttpEventType.Response:
    //       return this.apiResponse(event);
  
    //     default:
    //       console.log(event.type);
    //       return ` surprising upload event: ${event.type}.`;
    //   }
    // }



    // private fileUploadProgress(event) {
    //   const percentDone = Math.round((100 * event.loaded) / event.total);
    //   return { status: "progress", message: percentDone };
    // }
  
    // private apiResponse(event) {
    //   return event.body;
    // }
  
  
}
