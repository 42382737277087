import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './includes/footer/footer.component';
import { HeaderComponent } from './includes/header/header.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule, MatToolbarModule, MatListModule } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';

import { ToastrModule } from 'ngx-toastr';

// mat files


import {MatInputModule,MatIconModule} from '@angular/material';

import { FormsModule , ReactiveFormsModule} from '@angular/forms';


// spinner plugin
import { NgxSpinnerModule } from "ngx-spinner";

// social login plugin 
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";

// api hitting
import { HttpClientModule } from '@angular/common/http';



// import auth guard 
import { AuthGuard } from './auth.guard';
import { GOOGLE_API_KEY } from './constant';
import { MainPipe } from './main-pipe.module';
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';
// import { CreateRentalsComponent } from './create-rentals/create-rentals.component';
// import { BookRentalsComponent } from './book-rentals/book-rentals.component';
// import { PackageDetailsComponent } from './packages/package-details/package-details.component';


// for social login plugin 
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(GOOGLE_API_KEY)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("942746639392141")
  }
]);





export function provideConfig() {
  return config;
}





@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    
    // CreateRentalsComponent,
    // BookRentalsComponent,
      
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    MainPipe,
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    RouterModule,
    FormsModule , ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    SocialLoginModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    LazyLoadImageModule.forRoot({
    
    }),

    ToastrModule.forRoot(),
    

  ],
  providers: [AuthGuard,
    CookieService, {
    provide: AuthServiceConfig,
    useFactory: provideConfig
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
