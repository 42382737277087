import {AbstractControl, ValidationErrors, FormGroup} from '@angular/forms'

export class SpaceValidators{
    static canNotContainSpace(control : AbstractControl) : ValidationErrors{
        if(control.value){
            if((control.value as string).indexOf(' ') >= 0 && (control.value as string).trim().length == 0){
                return { canNotContainSpace : true};

            }
            return null;
        }
    }
    static onlyAlphabet(control : AbstractControl) : ValidationErrors{
        if(control.value){
            if((control.value as string).indexOf(' ') >= 0 && (control.value as string).trim().length == 0){
                return { canNotContainSpace : true};

            }
            return null;
        }
    }

    static checkPositiveNumber(control : AbstractControl) : ValidationErrors{
        if(control.value){
            console.log(control.value);
            console.log((control.value as number) <= 0);
            
            if((control.value as number) <= 0){
                return { checkPositiveNumber : true};

            }
            return null;
        }
        else if(control.value == 0){
            console.log(control.value);
            
            return { checkPositiveNumber : true};
        }
    }
    static checkPercent(control : AbstractControl) : ValidationErrors{
        if(control.value){
            console.log(control.value);
            console.log((control.value as number) <= 0);
            
            if((control.value as number) > 100 ){
                return { checkPercent : true};

            }
            return null;
        }
        else if(control.value == 0){
            console.log(control.value);
            
            return { checkPercent : true};
        }
    }
    static checkAdminPercent(control : AbstractControl) : ValidationErrors{
        if(control.value){
            console.log(control.value);
            console.log((control.value as number) <= 0);
            
            if((control.value as number) > 15 ){
                return { checkAdminPercent : true};

            }
            return null;
        }
        else if(control.value == 0){
            console.log(control.value);
            
            return { checkAdminPercent : true};
        }
    }
    static checkOrganizerPercent(control : AbstractControl) : ValidationErrors{
        if(control.value){
            console.log(control.value);
            console.log((control.value as number) <= 0);
            
            if((control.value as number) > 80 ){
                return { checkOrganizerPercent : true};

            }
            return null;
        }
        else if(control.value == 0){
            console.log(control.value);
            
            return { checkPercent : true};
        }
    }

    static checkPositiveNumberWithZero(control : AbstractControl) : ValidationErrors{
        if(control.value){
            console.log(control.value);
            console.log((control.value as number) < 0);
            
            if((control.value as number) < 0){
                return { checkPositiveNumberWithZero : true};

            }
            return null;
        }
       
    }
    
    
}