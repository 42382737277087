import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { TimePipe, TimePipe1 } from './pipes/time.pipe';
import { TwoDecimalPipe, OneDecimalPipe } from './pipes/two-decimal.pipe';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { ChangeIntPipe } from './pipes/int-parse.pipe';


@NgModule({
  declarations:[EllipsisPipe, TimePipe, TimePipe1, TwoDecimalPipe, OneDecimalPipe, OrdinalPipe, ChangeIntPipe],
  imports:[CommonModule],
  exports:[EllipsisPipe, TimePipe, TimePipe1, TwoDecimalPipe,OneDecimalPipe,  OrdinalPipe, ChangeIntPipe]
})

export class MainPipe{}