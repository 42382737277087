import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from "rxjs/operators";
import io from 'socket.io-client';
import { BASE_URL, SOCKET_BASE_URL } from 'src/app/constant';
import { CommonService } from 'src/app/service/common.service';
import { ChatList } from './chat.model';


@Injectable({
  providedIn: 'root'
})
export class ChatService {
  serverURL = BASE_URL
  socketBaseUrl = SOCKET_BASE_URL;

  private socket;
  constructor(
    private http: HttpClient, private router: Router,
    private cs: CommonService
  ) {
    this.socket = io(this.socketBaseUrl, { forceNew: false });
    if(this.socket) console.log("connected");
    
  }

  getMessages = () => {
    return Observable.create(
      (observer) => {
        this.socket.on('msgToServer', msg => {
          observer.next(msg)
          
        })
      }
    )
  }

  sendMessage(msg) {
    console.log(msg);
    this.socket.emit('msgToServer', msg)
  }

  isRead(data) {
    console.log(data);
    this.socket.emit('readMessage', data)
  }

  deleteMessage(data) {
    console.log(data);
    this.socket.emit('deleteMessage', data)
  }

  chats(data): Observable<HttpResponse<ChatList>> {
    console.log('sdfds');

    console.log(data);
    let request: any = data;
    var session = JSON.parse(localStorage.getItem("session"));
    const headers = new HttpHeaders().set('Authorization', session ? session.session_id : '');

    // return this.http.get<any>(this.serverURL + '/homeFeed',{headers:headers});
    return this.http.get(this.serverURL + '/chats', { headers, params: request, observe: 'response' }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.cs.handleError),
      // ...and calling .json() on the response to return data
      map((res: HttpResponse<ChatList>) => res)
    );
  }

}