import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UpdateService } from 'src/app/service/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  hideFooter: boolean;
  footerSubscribe: any;
  isShowFooter: boolean = false;

  constructor(private router: Router, private dataservice: UpdateService) {
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        console.log(event.url)


        if (event.url.includes('/reset-password') || event.url.includes('/event/add')
          || event.url.includes('/rental/add') || event.url.includes('/chat')) {
          this.hideFooter = true;

        } else {
          this.hideFooter = false;

        }
      })
  }

  ngOnInit() {
    this.dataservice.changeCartCount(true);
    this.dataservice.changeUnreadMessageCount(true)

    this.footerSubscribe = this.dataservice.footerCheckObservable.subscribe((res) => {
      if (res)
        this.isShowFooter = true;
    });

  }
  ngOnDestroy() {

    this.footerSubscribe.unsubscribe();
  }

  currentYearLong(): number {
    return new Date().getFullYear();
  }
}
