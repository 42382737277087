import { MediaMatcher } from '@angular/cdk/layout';
import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
// for api
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// for social login 
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { NgxSpinnerService } from "ngx-spinner";
import { filter } from 'rxjs/operators';
import { IMAGE_URL, LARGE_IMAGE_URL, MEDIUM_IMAGE_URL, SMALL_IMAGE_URL } from 'src/app/constant';
import { SpaceValidators } from 'src/app/directive/space.validator';
import { CommonService } from 'src/app/service/common.service';
import { UpdateService } from 'src/app/service/data.service';
import Swal from 'sweetalert2';
import { ApiService } from "../../service/api.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  publishEvent: number;
  publishRental: number;
  private cartCountSubscribe;
  private profileImageSubscribe;
  private profileInfoSubscribe;
  private eventIdSubscribe;
  private loginPopupSubscribe;
  private completeProfileSubscribe;
  eventData: any;
  staticCategories: any = []
  staticCategoriesForNearby: any = []

  eventModel = [
    'title',
    'event_type_id',
    'category_id',
    'event_ticket_id',
    'brand',
    'model',
    'car_type',
    'eve_attr_rent_type',
    'tags',
    'venue_id',
    'venue_name',
    'address',
    'address_2',
    'lat',
    'lng',
    'city',
    'state',
    'postal_code',
    'country',
    'start_date',
    'end_date',
    'start_time',
    'end_time',
    'time_zone',
    'cover_image',
    'summary',
    'event_media',
    'seater',
    'large_bags',
    'small_bags',
    'doors',
    'ac',
    'gearbox'
  ]

  copyEventButton: number;
  copyRentalButton: number;
  previewEvent: number;
  previewRental: number;
  cartCount: any = 0;
  unreadMessage: any = '';
  dashboardHeader: number;
  receiptHeaderSubscribe: any;
  receiptData: any;
  unreadMessageCountSubscribe: any;
  isDashboard: number;
  EventDashboardIdSub: any;

  homeDataFunc(arg0: number) {
    throw new Error("Method not implemented.");
  }

  loggedInUserData: any = {
  }

  defaultImageUrl = SMALL_IMAGE_URL;
  LARGE_IMAGE_URL = LARGE_IMAGE_URL;
  MEDIUM_IMAGE_URL = MEDIUM_IMAGE_URL;
  SMALL_IMAGE_URL = SMALL_IMAGE_URL;

  mobileQuery: MediaQueryList;

  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array.from({ length: 50 }, () =>
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);

  private _mobileQueryListener: () => void;
  googleLoggedIn: boolean;
  location: Coordinates;
  lat: any;
  lng: any;

  eventId;

  ngOnDestroy(): void {
    this.profileImageSubscribe.unsubscribe();
    this.profileInfoSubscribe.unsubscribe();
    this.loginPopupSubscribe.unsubscribe();
    this.completeProfileSubscribe.unsubscribe();
    this.eventIdSubscribe.unsubscribe();
    this.cartCountSubscribe.unsubscribe();
    this.receiptHeaderSubscribe.unsubscribe();
    this.unreadMessageCountSubscribe.unsubscribe();
    this.EventDashboardIdSub.unsubscribe();

    this.checkAffilateKey();

    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  checkAffilateKey() {
    var currentDate = new Date().toString();
    var affilateSessionObject = JSON.parse(sessionStorage.getItem('affilateSessionObject'));
    var expirationDate = affilateSessionObject.expiresAt;
    if (Date.parse(currentDate) > Date.parse(expirationDate)) {
      sessionStorage.removeItem('affilateSessionObject');
    }
  }

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));

  goToCreateEvent: any
  goToCreateRental: any
  @ViewChild('signup_modal', { static: false }) private content1;
  @ViewChild('login_modal', { static: false }) private content2;

  createEventHeader: any = 0;
  createRentalHeader: any = 0;
  hideHeader: boolean;
  user_exist: any = 0;
  session: any;
  // showLoginModalFields:any;

  // login variables
  user_login: boolean = false;
  loginForm: FormGroup;
  copyCancelForm: FormGroup;
  login_submit: boolean;
  remember_me_detail = {
    email: "",
    password: "",
    is_remember: false
  };
  login_error: any;

  // signup variables
  signUpForm: FormGroup;
  sign_up_submit: boolean;
  sign_up_error: any;

  // forgot var
  forgetForm: FormGroup;
  forget_submit: boolean;
  forget_email_error: string;

  changeHeader: number = 0;

  @ViewChild('login_modal', { static: false }) private content;

  constructor(changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authService: AuthService,
    private cs: CommonService,
    private route: ActivatedRoute,
    private update: UpdateService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private ApiService: ApiService,
    public modalService: NgbModal,
    private router: Router,
    private dataService: UpdateService) {

    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event.url.includes('/receipt')) {
          this.changeHeader = 1;
        }
        else if (event.url.includes('/event/add/basics')) {
          this.changeHeader = 0;
          this.publishEvent = 0;
          this.createEventHeader = 1;
          this.createRentalHeader = 0;
        }
        else if (event.url.includes('/event/add/6')) {
          this.changeHeader = 0;
          this.publishEvent = 1;
          this.copyEventButton = 1;
          this.previewEvent = 1;
          this.dashboardHeader = 0;
          this.createEventHeader = 2;
          this.createRentalHeader = 0;
        }
        else if (event.url.includes('/event/add/1')) {
          this.changeHeader = 0;
          this.publishEvent = 0;
          this.copyEventButton = 0;
          this.previewEvent = 0;
          this.dashboardHeader = 0;
          this.createEventHeader = 2;
          this.createRentalHeader = 0;
        }
        else if (event.url.includes('/event/add')) {
          this.changeHeader = 0;
          this.publishEvent = 0;
          this.copyEventButton = 1;
          this.previewEvent = 1;
          this.dashboardHeader = 0;
          this.createEventHeader = 2;
          this.createRentalHeader = 0;
        }
        else if (event.url.includes('/event-dashboard')) {
          this.changeHeader = 0;
          this.publishEvent = 0;
          this.copyEventButton = 1;
          this.previewEvent = 1;
          this.dashboardHeader = 1;
          this.createEventHeader = 2;
          this.createRentalHeader = 0;
          this.isDashboard = 1;
        }
        else if (event.url.includes('/rental/add/basic')) {
          this.changeHeader = 0;
          this.publishRental = 0;
          this.createRentalHeader = 1;
          this.createEventHeader = 0;
          this.changeHeader = 0;
        }
        else if (event.url.includes('/rental/add/6')) {
          this.changeHeader = 0;
          this.publishRental = 1;
          this.copyRentalButton = 1;
          this.previewRental = 1;
          this.dashboardHeader = 0;
          this.createRentalHeader = 2;
          this.changeHeader = 0;
        }
        else if (event.url.includes('/rental/add/1')) {
          this.changeHeader = 0;
          this.publishRental = 0;
          this.copyRentalButton = 0;
          this.previewRental = 0;
          this.dashboardHeader = 0;
          this.createRentalHeader = 2;
          this.changeHeader = 0;
        }
        else if (event.url.includes('/rental/add')) {
          this.changeHeader = 0;
          this.publishRental = 0;
          this.copyRentalButton = 1;
          this.previewRental = 1;
          this.dashboardHeader = 0;
          this.createRentalHeader = 2;
          this.changeHeader = 0;
        }
        else if (event.url.includes('/rental-dashboard')) {
          this.changeHeader = 0;
          this.publishRental = 0;
          this.copyRentalButton = 1;
          this.previewRental = 1;
          this.dashboardHeader = 1;
          this.createRentalHeader = 2;
          this.changeHeader = 0;
          this.isDashboard = 1;
        }
        else {
          this.changeHeader = 0;
          this.publishEvent = 0;
          this.publishRental = 0;
          this.createEventHeader = 0;
          this.changeHeader = 0;
          this.createRentalHeader = 0;
        }

        if (event.url.includes('/reset-password')) {
          this.hideHeader = true;

        } else {
          this.hideHeader = false;

        }


      })


    this.mobileQuery = media.matchMedia('(max-width: 991px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // router.events.subscribe(val => {
    //   if(location.path() == '/receipt'){
    //     this.changeHeader = 1;
    //   }
    //   else{
    //     this.changeHeader = 0;
    //   }
    // });
  }


  public socialSignIn(socialPlatform: string) {
    console.log(socialPlatform);

    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.authService.signIn(socialPlatformProvider).then(
      (userData) => {

        console.log(socialPlatform + " sign in data : ", userData);
        // Now sign-in with userData
        // ...

        this.loginForm.removeControl("password");
        console.log(this.loginForm);

        this.loginForm.addControl("first_name", new FormControl(userData.firstName));
        this.loginForm.addControl("last_name", new FormControl(userData.lastName));
        this.loginForm.addControl("image", new FormControl(userData.photoUrl));

        this.loginForm.patchValue({
          email: userData.email
        });
        if (socialPlatform === "facebook") {
          console.log(socialPlatform);
          this.loginForm.removeControl('fb_id');
          this.loginForm.addControl('fb_id', new FormControl(userData.id))
        }
        if (socialPlatform === "google") {
          console.log(socialPlatform);

          this.loginForm.removeControl('google_id');
          this.loginForm.addControl("google_id", new FormControl(userData.id));
          console.log(this.loginForm);
          //this.loginForm.addControl('img', new FormControl(userData.id))
        }

        console.log(this.loginForm)
        this.loginForm.addControl("password", new FormControl(''));
        console.log(this.loginForm);

        this.login(1, socialPlatform);

      }
    );
  }


  signOut(): void {
    this.authService.signOut();
  }

  dismisLoginModel() {
    this.modalService.dismissAll();
    this.login_submit = false;
    this.sign_up_submit = false;
    this.loginForm.reset();
    this.signUpForm.reset();
    this.user_exist = false;
  }

  //  login form return method
  get f() {
    return this.loginForm.controls;
  }

  //  signup form return method
  get f2() {
    return this.signUpForm.controls;
  }

  // forget form return method
  get f3() {
    return this.forgetForm.controls;
  }

  // create login form function starts here
  createLoginForm() {

    if (localStorage.getItem("remember_me_detail")) {
      this.remember_me_detail = JSON.parse(
        localStorage.getItem("remember_me_detail")
      );
    }
    this.loginForm = this.fb.group({
      email: [this.remember_me_detail.email, [Validators.required, Validators.email]],
      // password: [this.remember_me_detail.password, [Validators.required]],
      // fb_id: [null],
      // google_id: [null],

    });

    this.copyCancelForm = this.fb.group({
      cancelDelete: [1, Validators.required]
    });
  }

  cancelDeleteFun() {
    if (this.copyCancelForm.value.cancelDelete == 1) {
      this.cancleEvent();
    }
    else {
      this.deleteEvent();
    }
    this.modalService.dismissAll();
  }

  copyEvent() {
    if (this.isDashboard == 1)
      this.update.changeCopyEventSubject(true);
  }
  copyRental() {
    if (this.isDashboard == 1)
      this.update.changeCopyRentalSubject(true);
  }

  eventCopy(data) {


    const filtered: any = Object.keys(data)
      .filter(key => this.eventModel.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});
    Object.keys(filtered).forEach((key) => (filtered[key] == null) && delete filtered[key]);
    console.log(filtered);

    let tagsArr: any = [];
    let mediaArr: any = [];

    for (let i = 0; i < data.tags.length; i++) {
      tagsArr.push(data.tags[i].tag)
    }
    for (let i = 0; i < data.event_media.length; i++) {
      mediaArr.push({ type: data.event_media[i].type, media: data.event_media[i].media })
    }
    filtered.tags = tagsArr;
    filtered.event_media = mediaArr;
    console.log(filtered);

    /* let request = {
       eve_attr_rent_type: data.eve_attr_rent_type,
       title: data.title ? data.title : null,
       car_type: data.car_type ? data.car_type : null,
       venue_name: data.venue_name ? data.venue_name : null,
       address: data.address ? data.address : null,
       address_2: data.address_2 ? data.address_2 : null,
       location: data.location ? data.location : null,
       lat: data.lat ? data.lat : null,
       lng: data.lng ? data.lng : null,
       city: data.city ? data.city : null,
 
       state: data.state ? data.state : null,
       postal_code: data.postal_code ? data.postal_code : null,
       country: data.country ? data.country : null,
       start_date: data.start_date ? data.start_date : null,
       end_date: data.end_date ? data.end_date : null,
       start_time: data.start_time ? data.start_time : null,
       end_time: data.end_time ? data.end_time : null,
       time_zone: data.time_zone ? data.time_zone : null,
       cover_image: data.cover_image ? data.cover_image : null,
       summary: data.summary ? data.summary : null,
 
       suitable_for: data.suitable_for ? data.suitable_for : null,
       refund: data.refund ? data.refund : null,
       publish_status: 0,
       is_taxable: data.is_taxable ? data.is_taxable : null,
       tax_name: data.tax_name ? data.tax_name : null,
       tax_rate: data.tax_rate ? data.tax_rate : null,
       is_tax_applied: data.is_tax_applied ? data.is_tax_applied : null,
       event_type_id: data.event_type_id ? data.event_type_id : null,
       category_id: data.category_id ? data.category_id : null,
       user_id: data.user_id ? data.user_id : null,
 
 
       venue_id: data.venue_id ? data.venue_id : null,
       brand: data.brand ? data.brand : null,
       model: data.model ? data.model : null,
       min_price: data.min_price ? data.min_price : null,
       max_price: data.max_price ? data.max_price : null,
       min_start_date: data.min_start_date ? data.min_start_date : null,
       is_liked: data.is_liked ? data.is_liked : null,
       total_reviews: data.total_reviews ? data.total_reviews : null,
       total_bookings: data.total_bookings ? data.total_bookings : null,
 
     }*/


  }

  cancleEvent() {
    let request = { id: this.eventId }
    if (request.id) {
      this.cs.httpRequest('post', 'event/cancel', request).subscribe(
        (data: any) => {
          this.cs.showSuccess(data.message, 'success')
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.cs.showError(err.error.message, 'Error');
          if (err.status == 403) {
            this.cs.showError(err.error.message, 'Error');
            this.router.navigate(['/'])
          }
          if (err.status == 401) {
            this.cs.Forbidden_logout();
            this.update.changeLoginPopup(true);
            this.update.changeProfileImage(true)
          }
        }
      );
    }
  }

  deleteEvent() {

    let request = { id: this.eventId }

    if (request.id) {
      this.cs.httpRequest('delete', 'event', request).subscribe(
        (data: any) => {
          this.cs.showSuccess(data.message, 'success')
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.cs.showError(err.error.message, 'Error');
          if (err.status == 403) {
            this.cs.showError(err.error.message, 'Error');
            this.router.navigate(['/'])
          }
          if (err.status == 401) {
            this.cs.Forbidden_logout();
            this.update.changeLoginPopup(true);
            this.update.changeProfileImage(true)
          }
        }
      );
    }
  }

  // create signup form function starts here
  createSignUpForm() {

    this.signUpForm = this.fb.group({
      first_name: ["", [Validators.required, SpaceValidators.canNotContainSpace]],
      last_name: ["", [Validators.required, SpaceValidators.canNotContainSpace]],
      // url: ["http://104.197.18.112", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      // confirm_password: ["", [Validators.required, Validators.email]],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=(.*\d.*){1,})(?=(.*[a-zA-Z].*){1,})/)
        ]
      ],
      confirm_password: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=(.*\d.*){1,})(?=(.*[a-zA-Z].*){1,})/)
        ]
      ],

    });
  }

  // create forget form 
  createForgetForm() {
    this.forgetForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],

    });
  }

  // checkEmail(group: FormGroup) { // here we have the 'passwords' group
  //   let email = group.get('email').value;
  //   let confirmEmail = group.get('confirmEmail').value;

  //   return email === confirmEmail ? null : { notSame: true }     
  // }


  ngOnInit() {

    this.update.changeCartCount(true);
    this.update.changeUnreadMessageCount(true)

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.getCatgoryFunc();
    this.createLoginForm();
    this.createSignUpForm();
    this.createForgetForm();

    // hide login signup if there is local storage 
    if (localStorage.getItem("session")) {
      this.user_login = true;
      this.session = JSON.parse(localStorage.getItem("session"));
    } else {

      console.log("case 2")
      localStorage.removeItem("session");
      this.user_exist = 0
      this.user_login = false;
    }

    if (this.session) {
      this.setLoggedInUserData();
      this.getCartCount();
    }

    this.cartCountSubscribe = this.update.cartCountSubjectObservable.subscribe((res) => {

      let session_user1 = JSON.parse(localStorage.getItem('session'));
      if (res && session_user1) {

        this.getCartCount();
      }
      else if (res) {
        this.cartCount = 0
        this.user_login = false
      }
    });

    this.unreadMessageCountSubscribe = this.update.UnreadMessageObservable.subscribe((res) => {

      let session_user1 = JSON.parse(localStorage.getItem('session'));
      if (res && session_user1) {
        this.updateMessageCount();
      }
    });

    this.profileImageSubscribe = this.update.profileImageObservable.subscribe((res) => {

      let session_user1 = JSON.parse(localStorage.getItem('session'));
      if (res && session_user1) {

        console.log(session_user1.user.image);

        this.setLoggedInUserData();
      }
      else if (res) {
        this.user_login = false
      }
    });

    this.profileInfoSubscribe = this.update.profileInfoObservable.subscribe((res) => {

      let session_user1 = JSON.parse(localStorage.getItem('session'));
      if (res && session_user1) {
        console.log(session_user1.user);
        this.setLoggedInUserData();
      }
      else if (res) {
        this.user_login = false
      }
    });

    this.eventIdSubscribe = this.update.eventIdSubjectObservable.subscribe((res) => {
      let session_user1 = JSON.parse(localStorage.getItem('session'));
      if (res && session_user1) {
        console.log(session_user1.user);
        this.eventId = res;
      }
    });

    this.EventDashboardIdSub = this.route.params.subscribe(params => {
      console.log('hello');
      this.eventId = params.id;
      //DashboardComponent.eventId = this.eventId;
    });

    this.loginPopupSubscribe = this.update.LoginPopupSubjectObservable.subscribe((res) => {
      if (res) {
        this.openModal(this.content2, 'login');
      }
    });

    this.completeProfileSubscribe = this.update.CompleteProfileObservable.subscribe((res) => {
      if (res) {
        this.cs.showWarning('Please complete your profile first.', 'Warning');
        this.router.navigate(['/profile/edit-profile'])
      }
    });

    this.receiptHeaderSubscribe = this.update.receiptHeaderObservable.subscribe((res) => {
      if (res) {
        this.getTicket(res);
      }
    });

  }

  getTicket(ticketBookingId) {

    let request = { id: ticketBookingId };

    this.cs.httpRequest('get', 'event/booking', request).subscribe(
      (data: any) => {
        console.log(data);
        // this.count  = data.count;
        this.receiptData = data.booking;
      },
      (err: HttpErrorResponse) => {
        this.cs.showError(err.error.message, 'Error');
        if (err.status == 404) {
          this.cs.showWentWrong();
          this.router.navigate(['/']);
        }
        if (err.status == 403) {
          this.cs.showError(err.error.message, 'Error');
          this.router.navigate(['/'])
        }
        if (err.status == 401) {
          this.cs.Forbidden_logout();
          this.update.changeLoginPopup(true);
          this.update.changeProfileImage(true)
        }
      }
    );
  }

  ngDoCheck() {
    if (localStorage.getItem('session')) {
      this.user_exist = 1
      this.user_login = true;
    }
  }

  getCartCount() {
    var request = {}
    this.cs.httpRequest('get', 'event/cart-count', request).subscribe(
      (data: any) => {
        console.log(data);
        this.cartCount = data.cart_count;
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.cs.showError(err.error.message, 'Error');
          this.router.navigate(['/'])
        }
        if (err.status == 401) {
          this.cs.Forbidden_logout();
          this.update.changeLoginPopup(true);
          this.update.changeProfileImage(true)
        }
      }
    );
  }

  openModal(content, content1 = null) {

    this.login_error = '';



    if (content1 === 'login') {
      this.openLoginPopup();
    }
    else if (content1 === 'createEvent') {
      //this.goToCreateEvent = content1;
      this.openLoginPopup();
    }
    else if (content1 === 'createRental') {
      // this.goToCreateRental = content1;
      this.openLoginPopup();
    }
    else if (content1 === null) {
      this.goToCreateEvent = null;
      this.goToCreateRental = null;
    }

    this.modalService.dismissAll();
    this.modalService.open(content, { centered: true });
  }

  // remember password function starts here
  saveRememberMeDetail() {
    console.log(this.loginForm.value.email);
    this.remember_me_detail.email = this.loginForm.value.email
      ? this.loginForm.value.email
      : "";
    this.remember_me_detail.password = this.loginForm.value.password
      ? this.loginForm.value.password
      : "";
    this.remember_me_detail.is_remember = true;
    localStorage.setItem(
      "remember_me_detail",
      JSON.stringify(this.remember_me_detail)
    );
  }

  openLoginPopup() {
    this.user_exist = 1;
    this.loginForm.addControl('password', new FormControl(this.remember_me_detail.password, [Validators.required]));
    // this.loginForm.addControl('fb_id', new FormControl(null));
    // this.loginForm.addControl('google_id', new FormControl(null))
  }



  // get started function starts here
  getStarted() {
    this.login_error = "";
    if (this.loginForm.invalid) {
      this.login_submit = true;
    } else {
      this.spinner.show('get_started');
      this.login_submit = false;
      const apiInfo = {
        url: "user/started",
        method: "get"
      };
      const request = this.loginForm.value;
      this.ApiService.call(apiInfo, request).subscribe(
        data => {
          console.log(data)
          this.spinner.hide('get_started');
          if (data.user_exist) {
            this.user_exist = 1
            this.loginForm.addControl('password', new FormControl(this.remember_me_detail.password, [Validators.required]));
            this.loginForm.addControl('fb_id', new FormControl(null));
            this.loginForm.addControl('google_id', new FormControl(null))
          }
          else {
            this.login_error = "This user doesn't exist";

            this.openModal(this.content1);
            this.signUpForm.patchValue({ 'email': this.loginForm.value.email })
            this.createLoginForm();
            this.login_submit = false;
          }
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide('get_started');
          if (err.status == 403) {
            this.cs.showError(err.error.message, 'Error');
            this.router.navigate(['/'])
          }
          if (err.status == 401) {
            this.cs.Forbidden_logout();
            this.update.changeLoginPopup(true);
            this.update.changeProfileImage(true)
          }

        }
      );
    }
  }

  // remember me function starts here
  rememberMe(event): void {
    console.log(event.target.checked);
    if (event.target.checked) {
      this.saveRememberMeDetail();
    } else {
      localStorage.removeItem("remember_me_detail");
      this.remember_me_detail.email = "";
      this.remember_me_detail.password = "";
      this.remember_me_detail.is_remember = false;
    }
  }



  // login function starts here
  login(social_login = null, socialPlatform?: string) {
    this.login_error = "";
    if (this.loginForm.invalid) {
      this.login_submit = true;
    } else {
      this.spinner.show('login');
      this.login_submit = false;
      const apiInfo = {
        url: "user/login",
        method: "post"
      };

      let request = this.loginForm.value;

      if (social_login == 1) {

        request = {
          email: this.loginForm.value.email,
          first_name: this.loginForm.value.first_name,
          last_name: this.loginForm.value.last_name,
          image: this.loginForm.value.image
        }
        if (socialPlatform === 'facebook')
          request.fb_id = this.loginForm.value.fb_id;
        else if (socialPlatform === 'google')
          request.google_id = this.loginForm.value.google_id;
      }

      console.log(request);

      this.cs.httpRequest('post', 'user/login', request).subscribe(
        data => {
          console.log(data);

          console.log("After login" + data)
          this.spinner.hide('login');
          this.cs.showSuccess('Logged in successfully', 'Logged In')

          localStorage.removeItem("isSocialLogin");
          if (social_login == 1) {
            localStorage.setItem("isSocialLogin", JSON.stringify(true));
          }
          else {
            localStorage.setItem("isSocialLogin", JSON.stringify(false));
          }

          localStorage.setItem("session", JSON.stringify(data));
          this.setLoggedInUserData();
          localStorage.getItem("session");
          if (this.remember_me_detail.is_remember && !social_login) {
            this.saveRememberMeDetail();
          }
          if (this.goToCreateEvent) {
            this.router.navigate(['/event/add'])
          }
          if (this.goToCreateRental) {
            this.router.navigate(['/rental/add'])
          }
          this.user_login = true;
          this.modalService.dismissAll();

          this.update.changeCartCount(true);

        },
        (err: HttpErrorResponse) => {
          this.spinner.hide('login');
          if (err.status == 403) {
            this.cs.showError(err.error.message, 'Error');
            this.router.navigate(['/'])
          }
          if (err.status == 401) {
            this.cs.Forbidden_logout();
            this.update.changeLoginPopup(true);
            this.update.changeProfileImage(true)
          }
          console.log(err.error);
          this.login_error = err.error.message;
        }
      );
    }
  }

  updatePreview() {

    this.update.changePreviewValue(true);
  }
  saveAsDraft() {
    this.update.changeDraft(true);
  }
  publish() {
    this.update.changePublish(true);
  }

  setLoggedInUserData() {
    let session_user = JSON.parse(localStorage.getItem('session')).user;
    this.loggedInUserData = session_user;

    this.loggedInUserData.image = this.checkProfilePictureUrl(this.loggedInUserData.image);

    console.log(this.loggedInUserData);
    this.updateMessageCount();

  }

  updateMessageCount() {

    var request = {}
    this.cs.httpRequest('get', 'chat/unread-messages', request).subscribe(
      (data: any) => {
        console.log("unread message" + data);
        this.unreadMessage = data.unread_messages;
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.cs.showError(err.error.message, 'Error');
          this.router.navigate(['/'])
        }
        if (err.status == 401) {
          this.cs.Forbidden_logout();
          this.update.changeLoginPopup(true);
          this.update.changeProfileImage(true)
        }
      }
    );
  }

  checkProfilePictureUrl(imageUrl) {
    if (imageUrl) {
      if (imageUrl.includes('https://') || imageUrl.includes('https://')) {
        this.googleLoggedIn = true;
        return imageUrl;
      }
      else {
        this.googleLoggedIn = true;
        return this.defaultImageUrl + imageUrl;
      }
    }
    return null;
  }


  // signup function starts here
  signUp() {

    this.sign_up_error = "";
    if (this.signUpForm.invalid) {
      this.sign_up_submit = true;
    } else {
      if (this.signUpForm.value.password != this.signUpForm.value.confirm_password) {
        this.sign_up_error = "Password and confirm password should be same."
        return;
      }
      else {
        delete this.signUpForm.value.confirm_password;
      }
      this.spinner.show('signup');
      this.sign_up_submit = false;
      const apiInfo = {
        url: "",
        method: "post"
      };

      const request = this.signUpForm.value;

      this.cs.httpRequest('post', 'user/signup', request).subscribe(
        data => {
          console.log(data);
          this.createSignUpForm();

          this.spinner.hide('signup');
          // localStorage.setItem("session", JSON.stringify(data));

          // this.user_login = true;
          Swal.fire('', "A verification link has been sent on your email, Please verify it", 'success');
          this.modalService.dismissAll();
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide('signup');
          this.sign_up_error = err.error.message;
          if (err.status == 403) {
            this.cs.showError(err.error.message, 'Error');
            this.router.navigate(['/'])
          }
          if (err.status == 401) {
            this.cs.Forbidden_logout();
            this.update.changeLoginPopup(true);
            this.update.changeProfileImage(true)
          }
        }
      );
    }
  }




  // forgot passowrd function statrs here
  forgetPassword() {
    if (this.forgetForm.invalid) {
      this.forget_submit = true;
    } else {
      this.spinner.show('forgot');
      this.forget_submit = false;
      // console.log(this.loginForm.invalid)
      const apiInfo = {
        url: "",
        method: "post"
      };
      const request = this.forgetForm.value;

      this.cs.httpRequest('post', 'user/forgot-password', request).subscribe(
        data => {
          console.log(data);
          Swal.fire('', data.message, 'success');
          this.modalService.dismissAll();
          this.spinner.hide('forgot');
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide('forgot');
          this.forget_email_error = err.error.message;
          if (err.status == 403) {
            this.cs.showError(err.error.message, 'Error');
            this.router.navigate(['/'])
          }
          if (err.status == 401) {
            this.cs.Forbidden_logout();
            this.update.changeLoginPopup(true);
            this.update.changeProfileImage(true)
          }
        }
      );
    }
  }




  createEvent() {
    localStorage.removeItem('eventdata')
    if (this.cs.checkLogin()) {
      if (this.cs.checkProfileCompleted()) {
        this.router.navigate(['/event/add/basics'])
      }
      else {
        this.update.changeCompleteProfilePopup(true)
      }
    }
    else {
      this.openModal(this.content, 'createEvent')
    }
  }

  goToCartPage() {
    if (this.cs.checkLogin()) {
      // if (this.cs.checkProfileCompleted()) {
      // }
      // else {
      //   this.update.changeCompleteProfilePopup(true)
      // }
      if (this.cs.checkProfileCompleted()) {
        this.router.navigate(['/cart'])
      }
      else {
        this.update.changeCompleteProfilePopup(true)
      }

    }
    else {
      this.openModal(this.content, "login")
    }
  }
  goToChatPage() {
    if (this.cs.checkLogin()) {
      if (this.cs.checkProfileCompleted()) {
        this.router.navigate(['/chat'])
      }
      else {
        this.update.changeCompleteProfilePopup(true)
      }

    }
    else {
      this.openModal(this.content, "login")
    }
  }

  createRental() {
    if (this.cs.checkLogin()) {
      if (this.cs.checkProfileCompleted()) {
        this.router.navigate(['/rental/add/basic'])
      }
      else {
        this.update.changeCompleteProfilePopup(true)
      }

    }
    else {
      this.openModal(this.content, 'createRental')
    }
  }

  getCatgoryFunc() {
    var request = {}
    this.cs.httpRequest('get', 'static/categories', request).subscribe(
      (data: any) => {
        console.log(data);
        this.staticCategories = data.categories;
        this.staticCategories = this.cs.sortObject(this.staticCategories, 'name');
        this.staticCategoriesForNearby = this.staticCategories;
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.dataService.changeLoginPopup(true);
        }
      }
    );
  }


  goToBrowseEvent(id) {
    let request: any = {};

    request.categories = id;

    console.log(request);

    this.router.navigate(['/browse-events'], {
      queryParams:
      {
        categories: request.categories
      }
    }
    );
  }

  // logout function starts here
  logout() {
    this.createLoginForm();
    // this.showLoginModalFields = 0;
    this.user_exist = 0
    this.user_login = false;
    this.unreadMessage = 0;
    this.cs.logout();

  }



}
